<template>
	<div class="main-card mb-3 card pa-0 ma-0">
		<div :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']" class="card-body pa-0">
			<div
				:class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
				class="card-title card-header-tab card-header"
			>
				<div class="card-header-title font-size-lg text-capitalize font-weight-normal">
					{{ $t("reservation.lang_ourOpeningHours") }}
				</div>
				<div class="btn-actions-pane-right actions-icon-btn">
					<div class="btn-actions-pane-right actions-icon-btn">
						<v-btn
							:disabled="loading || this.loadData"
							@click="openConfigDialog"
							class="elevation-0"
							color="success"
							small
						>
							{{ $t("generic.lang_configWholeWeek") }}
						</v-btn>
						<v-btn
							:disabled="loading || this.loadData"
							:loading="loading"
							@click="saveTimes"
							class="elevation-0"
							color="primary"
							small
						>
							<v-icon>check</v-icon>
						</v-btn>
					</div>
				</div>
			</div>
			<v-divider class="pa-0 ma-0" />
			<v-row>
				<v-col cols="12">
					<v-form lazy-validation ref="form">
						<v-data-table
							:headers="headers"
							:items="days"
							:loading="loadData"
							hide-default-footer
							:no-data-text="$t('generic.lang_noEntriesFound')"
						>
							<template v-slot:item.day="{ item }">
								<v-checkbox
									:ripple="false"
									class="mb-3"
									:value="1"
									v-model="item.closedDay"
									:label="weekdays[item.day]"
								/>
							</template>
							<template v-slot:item.shift1="{ item }">
								<v-row align="center" justify="center">
									<v-col cols="12" sm="2" class="text-right">
										<v-simple-checkbox
											:ripple="false"
											@click="() => (item.start_shift1 = '') & (item.end_shift1 = '')"
											:disabled="item.closedDay === 0 || item.closedDay === null"
											v-model="item.closed_shift_1"
											class="mx-auto"
											:value="true"
										/>
									</v-col>
									<v-col cols="12" sm="5" class="pt-8">
										<BaseTimeInput
											:rules="[(v) => !!v]"
											:data-layout="KEYBOARD.KEYSETS.NORMAL"
											@focus="showTouchKeyboard"
											:disabled="item.closedDay === 0 || item.closedDay === null || !item.closed_shift_1"
											v-model="item.start_shift1"
											class="pb-0 pt-0 ma-0 mx-0"
											outlined
											dense
											:label="$t('generic.lang_from')"
										/>
									</v-col>

									<v-col cols="12" sm="5" class="pt-8">
										<BaseTimeInput
											:rules="[(v) => !!v]"
											:label="$t('generic.lang_to')"
											dense
											outlined
											class="pb-0 pt-0 ma-0 mx-0"
											:data-layout="KEYBOARD.KEYSETS.NORMAL"
											:disabled="item.closedDay === 0 || item.closedDay === null || !item.closed_shift_1"
											@focus="showTouchKeyboard"
											v-model="item.end_shift1"
										/>
									</v-col>
								</v-row>
							</template>

							<template v-slot:item.shift2="{ item }">
								<v-row align="center" justify="center">
									<v-col cols="12" sm="2" class="text-right">
										<v-simple-checkbox
											:ripple="false"
											@click="() => (item.start_shift2 = '') & (item.end_shift2 = '')"
											:disabled="item.closedDay === 0 || item.closedDay === null"
											v-model="item.closed_shift_2"
											class="mx-auto"
											:value="true"
										/>
									</v-col>
									<v-col cols="12" sm="5" class="pt-8">
										<BaseTimeInput
											:rules="[(v) => !!v]"
											:label="$t('generic.lang_from')"
											dense
											outlined
											class="pb-0 pt-0 ma-0 mx-0"
											:data-layout="KEYBOARD.KEYSETS.NORMAL"
											:disabled="item.closedDay === 0 || item.closedDay === null || !item.closed_shift_2"
											@change="changeStart($event, item, 2)"
											@focus="showTouchKeyboard"
											v-model="item.start_shift2"
										/>
									</v-col>

									<v-col cols="12" sm="5" class="pt-8">
										<BaseTimeInput
											:rules="[(v) => !!v]"
											:label="$t('generic.lang_to')"
											dense
											outlined
											class="pb-0 pt-0 ma-0 mx-0"
											:data-layout="KEYBOARD.KEYSETS.NORMAL"
											:disabled="item.closedDay === 0 || item.closedDay === null || !item.closed_shift_2"
											@focus="showTouchKeyboard"
											v-model="item.end_shift2"
										/>
									</v-col>
								</v-row>
							</template>

							<!--START V4-1352-->
							<template v-slot:item.deadline="{ item }">
								<v-row align="center" justify="center">
									<v-col cols="12" class="pt-8">
										<BaseTimeInput
											:label="$t('reservation.lang_Buffer')"
											dense
											outlined
											class="pb-0 pt-0 ma-0 mx-0"
											:data-layout="KEYBOARD.KEYSETS.NORMAL"
											:disabled="item.closedDay === 0 || item.closedDay === null"
											@change="changeStart($event, item, 2)"
											@focus="showTouchKeyboard"
										/>
									</v-col>
								</v-row>
							</template>
							<!--END V4-1352-->

							<template v-slot:item.closedDay="{ item }">
								<span v-if="false"> {{ item.closedDay }}</span>
							</template>
						</v-data-table>
					</v-form>
				</v-col>

				<!--config all days dialog-->
				<v-col cols="12">
					<v-dialog v-model="config_dialog" max-width="700" scrollable persistent>
						<v-card>
							<v-card-title :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'card-header']">
								{{ $t("generic.lang_opening_hours_for_the_whole_week") }}
							</v-card-title>

							<v-card-text class="pt-3">
								<v-form lazy-validation ref="config_all_form">
									<v-row>
										<v-col cols="12">
											<v-chip-group v-model="config.days" multiple>
												<v-chip :value="i" active-class="primary" v-for="(day, i) in weekdays" :key="i"
													>{{ day }}
												</v-chip>
											</v-chip-group>
										</v-col>

										<v-col cols="12">
											<v-row no-gutters>
												<v-col cols="12">
													<v-checkbox
														v-model="config.new_config.closed_shift_1"
														:value="true"
														:label="$t('generic.lang_shift') + ' 1'"
													/>
												</v-col>
												<v-col cols="12" sm="6" class="pa-2">
													<BaseTimeInput
														:rules="[(v) => !!v]"
														:label="$t('generic.lang_to')"
														dense
														outlined
														class="pb-0 pt-0 ma-0 mx-0"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:disabled="!config.new_config.closed_shift_1"
														@focus="showTouchKeyboard"
														v-model="config.new_config.start_shift1"
													/>
												</v-col>

												<v-col cols="12" sm="6" class="pa-2">
													<BaseTimeInput
														:rules="[(v) => !!v]"
														:label="$t('generic.lang_to')"
														dense
														outlined
														class="pb-0 pt-0 ma-0 mx-0"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:disabled="!config.new_config.closed_shift_1"
														@focus="showTouchKeyboard"
														v-model="config.new_config.end_shift1"
													/>
												</v-col>

												<v-col cols="12">
													<v-checkbox
														v-model="config.new_config.closed_shift_2"
														:value="true"
														:label="$t('generic.lang_shift') + ' 1'"
													/>
												</v-col>
												<v-col cols="12" sm="6" class="pa-2">
													<BaseTimeInput
														:rules="[(v) => !!v]"
														:label="$t('generic.lang_to')"
														dense
														outlined
														class="pb-0 pt-0 ma-0 mx-0"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:disabled="!config.new_config.closed_shift_2"
														@focus="showTouchKeyboard"
														v-model="config.new_config.start_shift2"
													/>
												</v-col>

												<v-col cols="12" sm="6" class="pa-2">
													<BaseTimeInput
														:rules="[(v) => !!v]"
														:label="$t('generic.lang_to')"
														dense
														outlined
														class="pb-0 pt-0 ma-0 mx-0"
														:data-layout="KEYBOARD.KEYSETS.NORMAL"
														:disabled="!config.new_config.closed_shift_2"
														@focus="showTouchKeyboard"
														v-model="config.new_config.end_shift2"
													/>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</v-form>
							</v-card-text>

							<v-card-actions class="card-footer pt-2 pb-2">
								<v-btn @click="closeConfigDialog" color="error" text>{{
									this.$t("generic.lang_cancel")
								}}</v-btn>

								<v-spacer />

								<v-btn @click="applyConfig" color="primary" elevation="0">
									{{ this.$t("generic.lang_apply") }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-col>
			</v-row>
			<div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
				<vue-touch-keyboard
					:accept="hideTouchKeyboard"
					:cancel="hideTouchKeyboard"
					:defaultKeySet="touchKeyboard.keySet"
					:input="touchKeyboard.input"
					:layout="touchKeyboard.layout"
					:options="touchKeyboard.options"
					class="internalWidthExpanded"
					id="onScreenKeyboard"
					v-if="touchKeyboard.visible"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Template from "../../settings/customerdisplay/Template";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";
import BaseTimeInput from "@/components/common/BaseTimeInput.vue";

export default {
	name: "OpeningTimes",
	components: { Template, BaseTimeInput },
	mixins: [mixin],
	data: () => {
		return {
			loading: false,
			loadData: false,
			d: [],
			config_dialog: false,
			config: {
				days: [],
				new_config: {
					closed_shift_1: false,
					closed_shift_2: false,
					start_shift1: "",
					start_shift2: "",
					end_shift1: "",
					end_shift2: "",
				},
			},
		};
	},
	computed: {
		weekdays: function () {
			return [
				this.$t("generic.Sonntag"),
				this.$t("generic.Montag"),
				this.$t("generic.Dienstag"),
				this.$t("generic.Mittwoch"),
				this.$t("generic.Donnerstag"),
				this.$t("generic.Freitag"),
				this.$t("generic.Samstag"),
			];
		},
		days: {
			get() {
				return this.d;
			},
			set(val) {
				this.d = val;
			},
		},
		headers: function () {
			return [
				{
					text: this.$t("generic.lang_day"),
					value: "day",
					sortable: false,
				},
				{
					text: this.$t("reservation.lang_mornings"),
					value: "shift1",
					sortable: false,
				},
				{
					text: this.$t("reservation.lang_afternoon"),
					value: "shift2",
					sortable: false,
				},
				{
					text: this.$t("reservation.lang_DeadlineBuffer"),
					value: "deadline",
					sortable: false,
				},
				{
					text: "",
					value: "closedDay",
					sortable: false,
					hide: true,
				},
			];
		},
	},
	mounted() {
		this.loadTimes();
	},
	methods: {
		applyConfig() {
			this.config.days.forEach((day) => {
				/**
				 * @type {{start_shift1: string, start_shift2: string, closedDay: number, end_shift2: string, day: *, end_shift1: string, closed_shift_2: boolean, closed_shift_1: boolean}}
				 */
				Object.assign(this.d[day], {
					day: day,
					closed_shift_1: this.config.new_config.closed_shift_1,
					start_shift1: this.config.new_config.start_shift1,
					end_shift1: this.config.new_config.end_shift1,
					closed_shift_2: this.config.new_config.closed_shift_2,
					start_shift2: this.config.new_config.start_shift2,
					end_shift2: this.config.new_config.end_shift2,
					closedDay: 1,
				});

				this.$forceUpdate();
			});

			this.config_dialog = false;

			this.config.days = [];
			this.config.new_config;
		},
		closeConfigDialog() {
			this.$refs.config_all_form.reset();
			this.config_dialog = false;
			this.config.days = [];
		},
		/**
		 * open config dialog for all days
		 */
		openConfigDialog() {
			this.config_dialog = true;
			this.config.days = [];
		},
		hmsToSecondsOnly(str) {
			if (str === "") return 0;
			var p = str.split(":"),
				s = 0,
				m = 1;

			while (p.length > 0) {
				s += m * parseInt(p.pop(), 10);
				m *= 60;
			}

			return s;
		},
		changeStart(event, item, shift) {
			if (
				this.hmsToSecondsOnly(item["start_shift" + shift]) > this.hmsToSecondsOnly(item["end_shift" + shift])
			) {
				item["start_shift" + shift] = "";
			}
		},
		loadTimes() {
			this.loadData = true;
			this.axios
				.post(ENDPOINTS.RESERVATION.SETTINGS.OPENHOURS.GET)
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						this.days = res.data.days || [
							{
								day: 0,
								closed_shift_1: true,
								start_shift1: "",
								end_shift1: "",
								closed_shift_2: true,
								start_shift2: "",
								end_shift2: "",
								closedDay: 1,
							},
							{
								day: 1,
								closed_shift_1: true,
								start_shift1: "",
								end_shift1: "",
								closed_shift_2: true,
								start_shift2: "",
								end_shift2: "",
								closedDay: 1,
							},
							{
								day: 2,
								closed_shift_1: true,
								start_shift1: "",
								end_shift1: "",
								closed_shift_2: true,
								start_shift2: "",
								end_shift2: "",
								closedDay: 1,
							},
							{
								day: 3,
								closed_shift_1: true,
								start_shift1: "",
								end_shift1: "",
								closed_shift_2: true,
								start_shift2: "",
								end_shift2: "",
								closedDay: 1,
							},
							{
								day: 4,
								closed_shift_1: true,
								start_shift1: "",
								end_shift1: "",
								closed_shift_2: true,
								start_shift2: "",
								end_shift2: "",
								closedDay: 1,
							},
							{
								day: 5,
								closed_shift_1: true,
								start_shift1: "",
								end_shift1: "",
								closed_shift_2: true,
								start_shift2: "",
								end_shift2: "",
								closedDay: 1,
							},
							{
								day: 6,
								closed_shift_1: true,
								start_shift1: "",
								end_shift1: "",
								closed_shift_2: true,
								start_shift2: "",
								end_shift2: "",
								closedDay: 1,
							},
						];
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.catch((err) => {})
				.finally(() => {
					this.loadData = false;
				});
		},
		saveTimes() {
			// if (!this.$refs.form.validate()) return;
			this.loading = true;
			this.axios
				.post(ENDPOINTS.RESERVATION.SETTINGS.OPENHOURS.UPDATE, {
					days: this.d,
				})
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_success"),
							color: "success",
						});
						localStorage.setItem("days", JSON.stringify(this.days));
					} else if (res.data.STATUS === "FAILED") {
						Events.$emit("showSnackbar", {
							message: res.data.msg,
							color: "error",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.then((err) => {
					Events.$emit("showSnackbar", {
						message: err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style>
.shadow-tabs .tab-item {
	z-index: 1 !important;
}

.shadow-tabs .tab-item-line {
	z-index: 0;
}

.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
	top: 5px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
	margin-bottom: 0px !important;
}
</style>
