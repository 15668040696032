<template>
  <div class="main-card mb-3 card pa-0 ma-0">
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '', 'card-body pa-0']">
      <v-divider class="pa-0 ma-0"/>
      <v-progress-linear
          v-if="this.loadData"
          color="primary darken-2"
          indeterminate
      ></v-progress-linear>
      <b-tabs content-class="mt-3">
        <b-tab :title="$t('settings.lang_reservationSettings')" class="active">
          <v-container class="pa-1">
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col align="right">
                    <v-btn :disabled="loading || loadData" :loading="loading" class="elevation-0"
                           color="primary"
                           fab small @click="saveConfig">
                      <v-icon>save</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

              </v-card-title>
              <v-card-text>
                <v-form ref="form" lazy-validation>
                  <v-row>
                    <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
                      <v-text-field v-model="form.reservation_email" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :disabled="loadData"
                                    :label="this.$t('generic.lang_emailAdresse')" :rules="[v => !!v]" dense outlined
                                    type="email"
                                    @focus="showTouchKeyboard"/>
                    </v-col>

                    <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
                      <users v-model="form.reservation_user_id" :disabled="loadData" :label="this.$t('generic.lang_selectedUser')"
                             :rules="[v => !!v]"
                             dense icon="person"
                             outlined v-bind:user="parseInt(form.reservation_user_id)"
                      />
                    </v-col>

                    <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
                      <v-text-field v-model="form.reservation_maxPerson" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :disabled="loadData"
                                    :label="this.$t('settings.lang_restaurantSizeMaxPersons')" :rules="[v => !!v]" dense
                                    outlined
                                    type="number" @focus="showTouchKeyboard"/>
                    </v-col>

                    <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
                      <v-text-field v-model="form.reservation_quota" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :disabled="loadData"
                                    :label="this.$t('settings.lang_restaurantQuota')" :rules="[v => !!v]" dense outlined
                                    type="number"
                                    @focus="showTouchKeyboard"/>
                    </v-col>

                    <v-col class="pt-0 pb-0 mt-0 mb-0" cols="12" sm="6">
                      <v-switch v-model="form.reservation_enabled" class="pa-0"
                                :label="$t('settings.lang_reservationAllowed')"/>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>

          </v-container>
        </b-tab>
        <b-tab :title="$t('generic.lang_menuType')" class="active">
          <menu-type></menu-type>
        </b-tab>
        <b-tab :title="$t('generic.lang_serviceType')" class="active">
          <service-type></service-type>
        </b-tab>
        <!--emails server config-->
        <b-tab :title="$t('generic.lang_mailServerSettings')" class="active">
          <reservation-email-config/>
        </b-tab>
        <!--emails template config-->
        <b-tab :title="$t('generic.lang_answerEmailTemplates')" class="active">
          <EmailTemplatesComponent/>
        </b-tab>
      </b-tabs>
      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                            :accept="hideTouchKeyboard"
                            :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidthExpanded"/>
      </div>
    </div>
  </div>
</template>

<script>
import Users from "../../common/Users";
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import MenuType from "./MenuType";
import ServiceType from "./ServiceType";
import ReservationEmailConfig from "@/components/reservation/settings/ReservationEmailConfig";
import EmailTemplatesComponent from "@/components/reservation/settings/EmailTemplatesComponent";

export default {
  name: "ReservationConfig",
  mixins: [mixin],
  components: {
    ReservationEmailConfig,
    Users, MenuType, ServiceType,
    EmailTemplatesComponent
},
  data: () => {
    return {
      usersLIST: [],
      loading: false,
      form: {
        reservation_enabled: false,
        reservation_email: null,
        reservation_maxPerson: 0,
        reservation_quota: 0,
        reservation_user_id: 0
      },
      loadData: false
    }
  },
  methods: {
    saveConfig() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      this.axios(ENDPOINTS.RESERVATION.SETTINGS.CONFIG.UPDATE, {
        method: "post",
        data: {
          configuration: {
            reservation_enabled: this.form.reservation_enabled,
            reservation_email: this.form.reservation_email,
            reservation_maxPerson: this.form.reservation_maxPerson,
            reservation_quota: this.form.reservation_quota,
            reservation_user_id: this.form.reservation_user_id
          }
        }
      }).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).then((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    loadConfig() {
      this.loadData = true;
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.CONFIG.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.form = res.data.configs;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadData = false;
      })
    }
  },
  mounted() {
    this.loadConfig();
  }
}
</script>

<style>
.v-input--selection-controls__input {
  padding: 0 !important;
}
</style>
