<template>
	<div>
		<page-title
			:heading="$t('settings.lang_reservationSettings')"
			:icon="icon"
			:subheading="$t('settings.lang_reservationSettings')"
			:showAddButton="isTypeTab"
			permission-add
			url-add="/reservation/settings/type/create"
		></page-title>
		<div class="app-main__inner">
			<settings-component @tab="currentTab" />
		</div>
	</div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import SettingsComponent from "../../../components/reservation/settings/SettingsComponent";

export default {
	components: {
		SettingsComponent,

		PageTitle,
	},
	data: () => ({
		icon: "pe-7s-settings icon-gradient bg-tempting-azure",
		isTypeTab: true,
	}),
	methods: {
		currentTab(isTypeTab) {
			this.isTypeTab = isTypeTab;
		},
	},
};
</script>
