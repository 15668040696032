<template>
  <v-card :loading="loading">
        <v-container class="pa-0">
          <v-form ref="serviceType" v-model="valid">
            <v-row no-gutters justify="center">
              <v-col class="pl-2 pr-2"  md="4" sm="6">
                <v-text-field :label="$t('generic.lang_serviceTypeName')" outlined dense :rules="[v=>v&&!!v || $t('generic.lang_requiredField')]" v-model="serviceTypeName"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              @focus="showTouchKeyboard"
                ></v-text-field>
              </v-col>
              <v-col class="pl-2 pr-2"  md="5" sm="6">
                <fontawesome-picker  dense :field-label="$t('generic.lang_chooseIcon')" v-model="serviceTypeIcon" type="meal" :rules="[v=>v&&!!v ||$t('generic.lang_requiredField')]"/>
              </v-col>

              <v-col md="3" align="center" sm="5">

                <template v-if="editMode">
                  <v-btn fab color="primary" elevation="2" class="ma-0 mr-1" depressed small :disabled="!valid||loadingTable" :loading="loadingTable" @click="updateServiceType">
                    <v-icon>
                      mdi-content-save
                    </v-icon>
                  </v-btn>
                  <v-btn fab color="error" elevation="2" class="ma-0 ml-1" depressed small :disabled="loadingTable" :loading="loadingTable" @click="cancelEdit()">
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>
                <v-btn v-else fab color="primary" elevation="2" class="ma-0" depressed small :disabled="!valid||loadingTable" :loading="loadingTable" @click="addServiceType">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
              <v-divider class="ma-0"/>
            </v-row>
          </v-form>
          <v-card class="mt-2">
            <v-data-table :headers="dataTableHeader" :items="serviceTypes" :loading="loading">
              <template v-slot:item.serviceTypeIcon="{item}">
                <font-awesome-icon :icon="['fal',item.serviceTypeIcon]"  />
              </template>
              <template v-slot:item.crudEdit="{item}" >
                <v-btn fab elevation="1" color="success" dark x-small @click="showEdit(item)">
                  <v-icon size="16px">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:item.crud="{item}" >
                <v-btn fab elevation="1" color="error" dark x-small @click="deleteServiceType(item)">
                  <v-icon size="16px">
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-container>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"/>
    </div>
  </v-card>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import swatches from "vue-swatches"
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {fal} from '@fortawesome/pro-light-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import FontawesomePicker from "@/components/common/iconPicker";
import Datatable from "@/components/datatable/Datatable";
library.add(
    fal,fas
)


export default {
  name: "GlobalSettings",
  components:{
    Datatable,
    FontawesomePicker,
    "font-awesome-icon":FontAwesomeIcon,
    swatches,
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      serviceTypes : [],
      loading: false,
      loadingTable:false,
      loadingShortNameTable:false,
      valid:false,
      shortNameValid:false,
      order:null,
      cancel:null,
      serviceTypeName:null,
      serviceTypeIcon:null,
      uuid:null,
      editMode:false,
      shortNameEditMode:false,
      dataTableHeader:[
        {
          text: this.$t('generic.lang_name'),
          value:'serviceTypeName'
        },
        {
          text: this.$t('generic.lang_icon'),
          value: 'serviceTypeIcon'
        },
        {
          text: '',
          value:'crud',
          align:'center',
          width:100
        },
        {
          text: '',
          value:'crudEdit',
          align:'center',
          width:100
        },
      ],
      rules: {
        timeRule: (v) => {
          return /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(v) || this.$t('generic.lang_invalidTimeDuration')
        }
      },
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.SERVICE.GET, {
      }).then((res) => {
        this.loading = false;
        this.serviceTypes = res.data.serviceTypes
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      });
    },
    addServiceType(){
      if(!this.$refs.serviceType.validate()){
        return;
      }
      this.loadingTable=true;
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.SERVICE.CREATE,{
        serviceTypeName:this.serviceTypeName,
        serviceTypeIcon:this.serviceTypeIcon,
      }).then(res=>{
        if(res.data.status==="SUCCESS"){
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_addedSuccessfully')
          });
          this.$refs.serviceType.reset()
          this.getData();
        }else{
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg||res.data.status
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(()=>{
        this.loadingTable=false;
      })

    },
    showEdit(item){
      this.editMode=true
      this.serviceTypeName=item.serviceTypeName
      this.serviceTypeIcon=item.serviceTypeIcon
      this.uuid=item.uuid
    },
    cancelEdit(){
      this.editMode=false
      this.$refs.serviceType.reset();
    },



    deleteServiceType(item) {
      this.$swal({
        title: this.$t('generic.lang_DeleteServiceTypeHead'),
        text: this.$t('generic.lang_deleteServiceTypeBody'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.loadingTable=true;
          this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.SERVICE.DELETE,{
            serviceTypeUuid:[item.uuid],
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_serviceTypeDeleted'),
                color: "success"
              });

              this.$refs.serviceType.reset()
              this.editMode=false;
              this.getData();
            } else {
              Events.$emit("showSnackbar", {
                color: "error",
                message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
              });
            }
          }).catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message
            });
          })
          this.loadingTable=false;
        }
      });
    },




    updateServiceType(){
      if(!this.$refs.serviceType.validate()){
        return;
      }

      this.loadingTable=true;

      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.SERVICE.UPDATE,{
        serviceTypeUuid:this.uuid,
        serviceTypeName:this.serviceTypeName,
        serviceTypeIcon:this.serviceTypeIcon
      }).then(res=>{

        //SUCCESS

        if(res.data.status==="SUCCESS"){
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_updatedSuccessfully')
          });
          this.$refs.serviceType.reset()
          this.editMode=false;
          this.getData();
        }else{
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg||res.data.status
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(()=>{
        this.loadingTable=false;
      })
    },
  }
}
</script>

<style scoped>

</style>