var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":"","scrollable":"","persistent":""},model:{value:(_vm.showCreateDialog),callback:function ($$v) {_vm.showCreateDialog=$$v},expression:"showCreateDialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"card-header pt-1",class:[this.$vuetify.theme.dark ? 'dark-bg' : '']},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-0",attrs:{"text":"","color":"error"},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',{staticClass:"px-1 py-10"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"data-layout":_vm.KEYBOARD.KEYSETS.NORMAL,"rules":[_vm.rules.required],"label":_vm.$t('erp.lang_TemplateName'),"outlined":"","required":""},on:{"focus":_vm.showTouchKeyboard},model:{value:(_vm.tmplName),callback:function ($$v) {_vm.tmplName=$$v},expression:"tmplName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"focusable":""}},[_c('v-expansion-panel',{staticClass:"pa-0 ma-0"},[_c('v-expansion-panel-header',{staticClass:"px-3 py-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("delivery.lang_individual_invoice_variables"))+" ")])]),_c('v-expansion-panel-content',{staticClass:"pa-0 pt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('b-form-input',{class:[this.$vuetify.theme.dark ? 'dark-bg' : ''],attrs:{"data-layout":_vm.KEYBOARD.KEYSETS.NORMAL,"placeholder":_vm.$t('datatables.lang_search')},on:{"focus":_vm.showTouchKeyboard},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pa-0 ma-0"},_vm._l((this.variations.filter((v) =>
                            v
                              .toLocaleLowerCase()
                              .includes(_vm.search.toLocaleLowerCase())
                          )),function(elt,i){return _c('v-col',{key:i,staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"id":'input_' + i,"value":elt,"background-color":_vm.$vuetify.theme.dark
                                ? 'dark-bg'
                                : 'grey lighten-3',"dense":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.copyText('input_' + i, elt)},"mouseout":_vm.out}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("content_copy")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.toolpitMsg)+" ")])]},proxy:true}],null,true)})],1)}),1)],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('quill-editor',{attrs:{"id":"editor","data-layout":_vm.KEYBOARD.KEYSETS.NORMAL,"options":_vm.quillOptions,"rules":[(v) => !!v],"output":"html"},on:{"focus":_vm.showTouchKeyboard},model:{value:(_vm.tmplText),callback:function ($$v) {_vm.tmplText=$$v},expression:"tmplText"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"card-footer text-right pt-1 pb-1",class:[this.$vuetify.theme.dark ? 'dark-bg' : '']},[_c('v-spacer'),_c('v-btn',{staticClass:"bg-danger text-light mx-0",on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(this.$t("generic.lang_cancel"))+" ")]),_c('v-btn',{staticClass:"bg-primary text-light mx-1",attrs:{"disabled":this.loading,"loading":this.loading},on:{"click":_vm.createTemplate}},[_vm._v(" "+_vm._s(this.$t("generic.lang_create"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }