<template>
  <v-card>
    <v-card-text class="pa-0">
      <v-divider class="pa-0 ma-0"/>
      <b-tabs>
        <b-tab  :title="$t('generic.lang_welcome')" >
          <WelcomeConfigComponent/>
        </b-tab>
        <b-tab  :title="$t('generic.lang_terms')" >
          <TermsConfigComponent/>
        </b-tab>
        <b-tab  :title="$t('generic.lang_Conditions')" >
          <ConditionConfigComponent/>
        </b-tab>
        <b-tab  :title="$t('generic.lang_imprint')" >
          <imprints-config-component/>
        </b-tab>
        <b-tab  :title="$t('generic.lang_calendar')" >
          <calendar-settings />
        </b-tab>
        <b-tab  :title="$t('generic.lang_offlineMessages')" >
          <offline-message></offline-message>
        </b-tab>
      </b-tabs>
    </v-card-text>
  </v-card>
</template>

<script>
import WelcomeConfigComponent from "@/components/reservation/settings/webinterface/WelcomeConfigComponent";
import TermsConfigComponent from "@/components/reservation/settings/webinterface/TermsConfigComponent";
import ConditionConfigComponent from "@/components/reservation/settings/webinterface/ConditionsConfigComponent";
import ImprintsConfigComponent from '@/components/reservation/settings/webinterface/ImprintsConfigComponent.vue';
import CalendarSettings from '@/components/reservation/settings/webinterface/CalendarSettings';
import offlineMessage from '@/components/reservation/settings/webinterface/offlineMessage';

export default {
name: "WebInterface",
  components: {CalendarSettings, ConditionConfigComponent, TermsConfigComponent, ImprintsConfigComponent, WelcomeConfigComponent,offlineMessage}
}
</script>

<style scoped>

</style>
