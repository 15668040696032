<template>
  <v-card :loading="loadingMenuTableTable ">
    <v-container class="pa-0">
        <v-container class="pa-0">
          <v-form ref="menuTypeForm" >
            <v-row no-gutters justify="center">
              <v-col class="pl-2 pr-2"  md="4" sm="6">
                <v-text-field :label="$t('generic.lang_menuTypeName')" outlined dense :rules="[v=>v&&!!v || $t('generic.lang_requiredField')]" v-model="menu.name"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              @focus="showTouchKeyboard"
                ></v-text-field>
              </v-col>
              <v-col class="pl-2 pr-2"  md="5" sm="6">

                <swatches
                    inline v-model="menu.color"
                    background-color="transparent"
                ></swatches>
              </v-col>

              <v-col md="3" align="center" sm="5">

                <template v-if="EditMode">
                  <v-btn fab color="primary" elevation="2" class="ma-0 mr-1" depressed small :disabled="loadingMenuTableTable " :loading="loadingMenuTableTable " @click="updateMenuType">
                    <v-icon>
                      mdi-content-save
                    </v-icon>
                  </v-btn>
                  <v-btn fab color="error" elevation="2" class="ma-0 ml-1" depressed small :disabled="loadingMenuTableTable " :loading="loadingMenuTableTable " @click="cancelEdit">
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>
                <v-btn v-else fab color="primary" elevation="2" class="ma-0" depressed small :loading="loadingMenuTableTable " @click="addMenuType">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
              <v-divider class="ma-0"/>
            </v-row>
          </v-form>
          <v-card class="mt-2">
            <Datatable
                :api-endpoint="ENDPOINTS.RESERVATION.SETTINGS.MENU.DATATABLE"
                :datatable-headers="menuTypeHeaders"
                no-excel-export
                @editEntry="showEdit"
                @deleteEntries="deleteEntries"
                @deleteEntry="deleteEntry"
                ref="menuType"
                show-delete-buttons
                show-edit-buttons
                :permissionDelete="true"
                :permissionEdit="true"
            />
          </v-card>
        </v-container>
    </v-container>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"/>
    </div>
  </v-card>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import swatches from "vue-swatches"
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {fal} from '@fortawesome/pro-light-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import FontawesomePicker from "@/components/common/iconPicker";
import Datatable from "@/components/datatable/Datatable";
library.add(
    fal,fas
)


export default {
  name: "GlobalSettings",
  components:{
    Datatable,
    FontawesomePicker,
    "font-awesome-icon":FontAwesomeIcon,
    swatches,
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      loading: false,
      loadingMenuTableTable :false,
      EditMode:false,
      menuType : [],
      menu:{
        uuid:null,
        name:null,
        color:null,
      },
      data:[],// lunch types goes here
      dataTableHeader:[
        {
          text: this.$t('generic.lang_name'),
          value:'mealTypeName'
        },
        {
          text: this.$t('generic.lang_icon'),
          value: 'mealTypeIcon'
        },
        {
          text: '',
          value:'crud',
          align:'center',
          width:100
        },
        {
          text: '',
          value:'crudEdit',
          align:'center',
          width:100
        },
      ],
      rules: {
        timeRule: (v) => {
          return /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(v) || this.$t('generic.lang_invalidTimeDuration')
        }
      },
      menuTypeHeaders:[
        {
          text: 'uuid',
          value:'uuid',
          width:100,
          hide:true,
        },
        {
          text: this.$t('generic.lang_name'),
          value:'menuTypeName'
        },
        {
          text: this.$t('erp.lang_warecreate_color'),
          value: 'menuTypeColor'
        },
      ],

    }
  },
  watch:{
  },
  mounted() {
  },
  methods: {
    addMenuType(){

      if(!this.$refs.menuTypeForm.validate()){
        return;
      }

      this.loadingMenuTableTable =true;

      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.MENU.CREATE,{
        menuTypeName:this.menu.name,
        menuTypeColor:this.menu.color,
      }).then(res=>{

        //SUCCESS

        if(res.data.status==="SUCCESS"){
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_addedSuccessfully')
          });
          this.$refs.menuTypeForm.reset()
          this.$refs.menuType.getDataFromApi();
          this.menu.color = "";

        }else{
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg||res.data.status
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(()=>{
        this.loadingMenuTableTable =false;
      })

    },

    showEdit(item){
      this.EditMode=true
      this.menu.uuid=item.uuid
      this.menu.name=item.menuTypeName
      this.menu.color=item.menuTypeColor

    },
    cancelEdit(){
      this.EditMode=false
      this.$refs.menuTypeForm.reset();
    },
    updateMenuType(){
      if(!this.$refs.menuTypeForm.validate()){
        return;
      }

      this.loadingMenuTableTable =true;

      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.MENU.UPDATE,{
        menuTypeUuid:this.menu.uuid,
        menuTypeName:this.menu.name,
        menuTypeColor:this.menu.color,
      }).then(res=>{

        //SUCCESS

        if(res.data.status==="SUCCESS"){
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_updatedSuccessfully')
          });
          this.$refs.menuTypeForm.reset()
          this.EditMode=false;
          this.$refs.menuType.getDataFromApi();
          this.menu.color = "";
        }else{
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg||res.data.status,
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(()=>{
        this.loadingMenuTableTable =false;
      })
    },
    deleteEntry(entry) {
      this.deleteMenuType([entry.uuid])
    },
    deleteEntries(uuids) {
      this.deleteMenuType(uuids);
    },
    deleteMenuType(uuidsToDelete) {
      this.$swal({
        title: this.$t('generic.lang_DeleteMenuTypeHead'),
        text: this.$t('generic.lang_deleteMenuTypeBody'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.MENU.DELETE,{
            menuTypeUuid:uuidsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_menuTypeDeleted'),
                color: "success"
              });

              this.$refs.menuType.getDataFromApi();
            } else {
              Events.$emit("showSnackbar", {
                color: "error",
                message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
              });
            }
          }).catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message
            });
          })
        }
      });
    },
  }
}
</script>

<style scoped>

</style>