<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-text-field :loading="loading" type="number" :min="5" v-model="form.reservation_calendar_steep" dense outlined
                      :label="$t('generic.lang_timeChooserStep')">
          <template slot="append">
            <span class="pt-1">{{ $t('generic.lang_minutes') }}</span>
          </template>
        </v-text-field>
      </v-col>

      <v-col class="text-right" cols="12">
        <v-spacer/>
        <v-btn block elevation="0" @click="saveConfig" :disabled="loading" :loading="loading" class="mx-auto"
               color="primary">
          {{ this.$t("generic.lang_save") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "CalendarSettings.vue",
  data: () => ({
    loading: false,
    form: {
      reservation_calendar_steep: 5
    }
  }),
  mounted() {
    this.loadConfig();
  },
  methods: {
    loadConfig() {
      this.loadData = true;
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.CONFIG.GET).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.form = res.data.configs;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        console.log(err)
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadData = false;
      })
    },
    saveConfig() {
      this.loading = true;

      this.axios(ENDPOINTS.RESERVATION.SETTINGS.CONFIG.UPDATE, {
        method: "post",
        data: {
          configuration: {
            reservation_calendar_steep: this.form.reservation_calendar_steep
          }
        }
      }).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_success'),
            color: "success"
          });
        } else if (res.data.STATUS === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).then((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>
