<template>
	<v-container fluid>
		<tabs
			:currentTab="currentTab"
			:line-class="'tab-item-line'"
			:tab-active-class="'tab-item-active'"
			:tab-class="'tab-item'"
			:tabs="this.tabs"
			:wrapper-class="'body-tabs shadow-tabs'"
			@onClick="handleClick"
		/>

		<div class="content mt-6 pa-0">
			<!--reservation types -->
			<div v-if="currentTab === 'tab1'">
				<reservation-type />
			</div>

			<!-- confirmation config -->
			<div v-if="currentTab === 'tab2'">
				<reservation-config />
			</div>

			<!-- opening times -->
			<div v-if="currentTab === 'tab3'">
				<opening-times />
			</div>

			<!-- Web Interface-->
			<div v-if="currentTab === 'tab4'">
				<WebInterface />
			</div>
		</div>
	</v-container>
</template>

<script>
import Tabs from "vue-tabs-with-active-line";
import ReservationType from "./ReservationType";
import ReservationConfig from "./ReservationConfig";
import OpeningTimes from "./OpeningTimes";
import WebInterface from "@/components/reservation/settings/WebInterface";

export default {
	components: {
		WebInterface,
		OpeningTimes,
		ReservationConfig,
		ReservationType,
		Tabs,
	},
	name: "SettingsComponent",
	data: () => {
		return {
			currentTab: "tab1",
		};
	},
	computed: {
		tabs: function () {
			return [
				{
					title: this.$t("generic.lang_constReservationType"),
					value: "tab1",
				},
				{
					title: this.$t("settings.lang_settings"),
					value: "tab2",
				},
				{
					title: this.$t("reservation.lang_ourOpeningHours"),
					value: "tab3",
				},
				{
					title: this.$t("generic.lang_webInterface"),
					value: "tab4",
				},
			];
		},
	},
	methods: {
		handleClick(newTab) {
			if (newTab === "tab1") this.$emit("tab", true);
			else this.$emit("tab", false);
			this.currentTab = newTab;
		},
	},
};
</script>

<style scoped>
.tabs > .tabs__item {
	width: auto !important;
}

.tabs__item_active,
.tabs__active-line,
.shadow-tabs .tab-item-line {
	padding-top: 20px !important;
}

.tabs__item:hover {
	border: none;
	outline: none;
}

.tabs__item:focus {
	border: none;
	outline: none;
}
</style>
