<template>
	<Datatable
		:api-endpoint="ENDPOINTS.RESERVATION.SETTINGS.TYPES.DATATABLE"
		:datatable-headers="typesDatatableHeaders"
		show-create-buttons
		show-delete-buttons
		show-edit-buttons
		@createEntry="createEntry"
		@deleteEntry="deleteEntry"
		@editEntry="editEntry"
		ref="itemDatatable"
		show-select-checkbox
		enable-multi-sort
	>
		<template v-slot:item.icon="{ item }">
			<div class="my-1" v-if="item.icon">
				<font-awesome-icon :icon="['fal', item.icon]" /> {{ item.icon }}
			</div>
			<div class="my-1" v-else style="color: red">NO ICON SPECIFIED</div>
		</template>
		<template v-slot:item.color="{ item }">
			<div class="color-circle" :style="{ backgroundColor: item.color }"></div>
		</template>
	</Datatable>
</template>

<script>
import Datatable from "../../datatable/Datatable";

import { ENDPOINTS } from "@/config";
import mixin from "../../../mixins/KeyboardMixIns";
import Template from "../../settings/customerdisplay/Template";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(fal, fas);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Events } from "@/plugins/events";

export default {
	name: "ReservationType",

	components: {
		Datatable,
		Template,
		"font-awesome-icon": FontAwesomeIcon,
	},

	async mounted() {},

	mixins: [mixin],

	data() {
		return {
			ENDPOINTS,
			typesDatatableHeaders: [
				{
					text: "uuid",
					value: "id",
					align: " d-none",
				},
				{
					text: this.$t("generic.lang_name"),
					value: "name",
				},
				{
					text: this.$t("generic.lang_reservationTime"),
					value: "maxTime",
				},
				{
					text: this.$t("generic.lang_maxPersons"),
					value: "maxPerson",
				},
				{
					text: this.$t("generic.lang_chooseIcon"),
					value: "icon",
				},
				{
					text: this.$t("erp.lang_warecreate_color"),
					value: "color",
				},
			],
		};
	},
	methods: {
		deleteData: function (uuidsToDelete = []) {
			this.$swal({
				title: this.$t("reservation.lang_deleteReservationType"),
				text: this.$t("reservation.lang_deleteReservationTypeText"),
				cancelButtonText: this.$t("generic.lang_cancel"),
				confirmButtonText: this.$t("generic.lang_delete"),
				icon: "warning",
				showCancelButton: true,
				showLoaderOnConfirm: true,
				preConfirm: () => {
					if (!uuidsToDelete || !uuidsToDelete.length || uuidsToDelete.length === 0) {
						uuidsToDelete = [];
						uuidsToDelete.push(this.id);
					}
					console.log(uuidsToDelete);
					this.axios
						.post(ENDPOINTS.RESERVATION.SETTINGS.TYPES.UPDATE, {
							deleted: uuidsToDelete,
						})
						.then((res) => {
							if (res.status === 200) {
								Events.$emit("showSnackbar", {
									message: this.$t("erp.lang_itemSuccessfulDeleted"),
									color: "success",
								});

								this.$refs.itemDatatable.getDataFromApi();
								this.$refs.itemDatatable.resetSelectedRows();
							} else {
								Events.$emit("showSnackbar", {
									message: this.$t("generic.lang_errorOccurred"),
									color: "error",
								});
							}
						});
				},
				allowOutsideClick: () => !this.$swal.isLoading(),
			});
		},
		deleteEntry(entry) {
			this.id = entry.id;
			this.deleteData();
		},
		createEntry() {
			this.$router.push((this.$route.fullPath + "/create").replace("//", "/"));
		},
		editEntry(entry) {
			let parentId = entry.itemIsVariation ? entry.itemIsVariation : entry.itemIsAddOnBarcode;
			if (parentId) {
				this.id = parentId;
			} else {
				this.id = entry.id;
				this.text = entry.name;
			}
			this.$router.push({ name: "reservation.settings.type.edit", params: { id: this.id } });
		},
	},
	computed: {},
};
</script>

<style scoped>
details > summary::-webkit-details-marker {
	display: none;
}

details:active,
summary:active,
details:focus,
summary:focus {
	outline: none !important;
	border: none !important;
}

details {
	background: #fff;
	font-size: 16px;
	border-bottom: 1px solid #e0e0e0;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
	display: flex;
}

details div.content {
	padding: 0px 24px 24px 24px;
}

details[open] summary:after {
	color: #9e9e9e;
	content: "expand_less";
	font-family: "Material Icons";
}

details[open] {
	border: none;
	border-radius: 2px;
}

details[open]:first-child {
	margin-top: 0;
	margin-bottom: 16px;
	border: none;
}

details[open]:last-child {
	margin-top: 16px;
	margin-bottom: 0px;
	border: none;
}

summary {
	outline: none;
	cursor: pointer;
	padding: 16px 24px;
	color: #212121;
	position: relative;
	font-size: 15px;
}

summary:hover {
	background: #eeeeee;
}

@media (prefers-color-scheme: dark) {
	summary:hover {
		background: none;
	}
}

details[open] summary:hover {
	background: none;
}

summary::after {
	content: "expand_more";
	font-family: "Material Icons";
	color: #9e9e9e;
	font-size: 1.5em;
	padding: 0;
	text-align: center;
	margin-top: -5px;
	position: absolute;
	top: calc(50% - 12px);
	bottom: 0;
	right: 0;
	width: 6%;
}

summary {
	list-style-type: none;
}

.color-circle {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: "1px solid #ccc";
	background-color: red;
}
</style>
