<template>
  <v-dialog v-model="showCreateDialog" fullscreen scrollable persistent>
    <v-card tile>
      <v-card-title
        :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
        class="card-header pt-1"
      >
        <v-spacer />
        <v-btn @click="closeDialog" class="ma-0" text color="error">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-1 py-10">
        <v-form lazy-validation ref="form">
          <v-row class="mx-0">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="tmplName"
                @focus="showTouchKeyboard"
                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                :rules="[rules.required]"
                :label="$t('erp.lang_TemplateName')"
                outlined
                required
              >
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-expansion-panels focusable>
                <v-expansion-panel class="pa-0 ma-0">
                  <v-expansion-panel-header class="px-3 py-2">
                    <span>
                      {{ $t("delivery.lang_individual_invoice_variables") }}
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pa-0 pt-2">
                    <v-row>
                      <v-col cols="12">
                        <b-form-input
                          :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
                          :placeholder="$t('datatables.lang_search')"
                          v-model="search"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-row class="pa-0 ma-0">
                          <v-col
                            :key="i"
                            class="pt-0 pb-0"
                            cols="12"
                            md="6"
                            sm="12"
                            v-for="(elt, i) in this.variations.filter((v) =>
                              v
                                .toLocaleLowerCase()
                                .includes(search.toLocaleLowerCase())
                            )"
                          >
                            <v-text-field
                              :id="'input_' + i"
                              :value="elt"
                              :background-color="
                                $vuetify.theme.dark
                                  ? 'dark-bg'
                                  : 'grey lighten-3'
                              "
                              dense
                              outlined
                              readonly
                            >
                              <template v-slot:append>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      @click="copyText('input_' + i, elt)"
                                      @mouseout="out"
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon>content_copy</v-icon>
                                    </v-btn>
                                  </template>
                                  {{ toolpitMsg }}
                                </v-tooltip>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>

            <v-col class="py-0" cols="12">
              <quill-editor
                id="editor"
                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                :options="quillOptions"
                :rules="[(v) => !!v]"
                @focus="showTouchKeyboard"
                output="html"
                v-model="tmplText"
              >
              </quill-editor>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions
        :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
        class="card-footer text-right pt-1 pb-1"
      >
        <v-spacer />
        <v-btn @click="closeDialog" class="bg-danger text-light mx-0">
          {{ this.$t("generic.lang_cancel") }}
        </v-btn>

        <v-btn
          :disabled="this.loading"
          :loading="this.loading"
          @click="createTemplate"
          class="bg-primary text-light mx-1"
        >
          {{ this.$t("generic.lang_create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ENDPOINTS } from "@/config";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import mixin from "@/mixins/KeyboardMixIns";
import { Events } from "@/plugins/events";

export default {
  name: "CreateEmailTemplateComponent",
  components: { quillEditor },
  mixins: [mixin],
  props: {
    showCreateDialog: {
      type: Boolean,
      default: false,
    },
    variations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      search: "",
      tmplName: "",
      tmplText: "",
      toolpitMsg: this.$t("generic.lang_CopyToClipboard"),
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image', 'video']
          ],
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          }
        }
      },
      rules: {
        required: (val) => {
          return !!val || this.$t('generic.lang_requiredField')
        },
      }
    };
  },
  methods: {
    out() {
      this.toolpitMsg = this.$t("generic.lang_CopyToClipboard");
    },
    copyText(id, elt) {
      elt = elt.substring(elt.search("{{"), elt.search("}}") + 2);
      //console.log(id)
      var copyText = document.getElementById(id);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.toolpitMsg = this.$t("generic.lang_copied") + ": " + elt;
      this.tmplText += " " + elt;
    },
    createTemplate() {
      if (!this.$refs.form.validate()) return;
      if (this.tmplText === "") {
        Events.$emit("showSnackbar", {
          message: this.$t("generic.lang_pleaseSetYourTemplateText"),
          color: "error",
        });
        return;
      }

      this.loading = true;

      this.axios
        .post(ENDPOINTS.RESERVATION.SETTINGS.EMAILTEMPLATES.CREATE, {
          name: this.tmplName,
          text: this.tmplText,
        })
        .then((res) => {
          if (res.data.status) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_success"),
              color: "success",
            });

            this.closeDialog();
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeDialog() {
      this.$emit("closeCreateDialog");
    },
  },
};
</script>
<style>
#editor{
  height: 600px !important;
  max-height: 700px !important;
}
</style>
