<template>
	<v-card elevation="0">
		<v-card-text class="pa-0">
			<v-col class="pa-0 ma-0" cols="12">
				<v-form lazy-validation ref="form">
					<v-row class="pa-0 ma-0">
						<v-col class="" cols="12">
							<v-subheader
								><strong>{{ $t("generic.lang_title") }}</strong></v-subheader
							>
							<quill-editor
								id="editor"
								class="mb-8"
								:data-layout="KEYBOARD.KEYSETS.NORMAL"
								:options="quillOptions"
								@focus="showTouchKeyboard"
								output="html"
								v-model="offlineTitle"
							>
							</quill-editor>
						</v-col>
					</v-row>
				</v-form>
			</v-col>
			<v-col class="pa-0 ma-0" cols="12">
				<v-form lazy-validation ref="form">
					<v-row class="pa-0 ma-0">
						<v-col class="" cols="12">
							<v-subheader
								><strong>{{ $t("generic.lang_message") }}</strong></v-subheader
							>
							<quill-editor
								id="editor2"
								class="mb-8"
								:data-layout="KEYBOARD.KEYSETS.NORMAL"
								:options="quillOptions"
								@focus="showTouchKeyboard"
								output="html"
								v-model="offlineBody"
							>
							</quill-editor>
						</v-col>
					</v-row>
				</v-form>
			</v-col>
			<v-spacer></v-spacer>
		</v-card-text>
		<v-card-actions class="card-footer text-right pt-1 pb-1">
			<v-spacer />
			<v-btn :disabled="loading" :loading="loading" @click="update" class="bg-success text-light mx-1">
				{{ this.$t("generic.lang_apply") }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { quillEditor } from "vue-quill-editor";
import mixin from "@/mixins/KeyboardMixIns";
import Quill from "quill";
import QuillResize from "quill-resize-module";

Quill.register("modules/resize", QuillResize);

const FontAttributor = Quill.import("attributors/class/font");
let SizeStyle = Quill.import("attributors/style/size");
FontAttributor.whitelist = ["Poppins", "Roboto", "Nunito", "Dancingscript"];
SizeStyle.whitelist = [
	"8px",
	"10px",
	"12px",
	"14px",
	"16px",
	"18px",
	"20px",
	"24px",
	"28px",
	"32px",
	"36px",
	"40px",
	"48px",
	"56px",
	"64px",
	"72px",
	"80px",
	"96px",
	"128px",
];
Quill.register(FontAttributor, true);
Quill.register(SizeStyle, true);

// Fix : Dynamicaly changing font style for each font item
FontAttributor.whitelist.forEach((font) => {
	const style = document.createElement("style");
	style.innerHTML = `.ql-font-${font} { font-family: ${font}; }`;
	document.head.appendChild(style);
});

export default {
	name: "offlineMessage",
	components: { quillEditor },
	mixins: [mixin],
	data: () => ({
		loading: false,
		quillOptions: {
			modules: {
				toolbar: [
					[{ color: [] }],
					["image", "video", "link", "clean"],
					["bold", "italic", "underline", "strike"],
					[{ size: SizeStyle.whitelist }],
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					[{ align: [] }],
					[{ font: FontAttributor.whitelist }],
					[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
				],
			},
		},
		message: null,
		title: null,
		offlineTitle: null,
		offlineBody: null,
		form: {
			reservation_calendar_steep: 5,
		},
	}),
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this.axios
				.post(ENDPOINTS.RESERVATION.SETTINGS.WEBINTERFACE.OFFLINEMESSAGE.GET)
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						this.offlineTitle = res.data.data.offlineTitle;
						this.offlineBody = res.data.data.offlineBody;
					} else {
						Events.$emit("showSnackbar", {
							message: res.data.msg || res.data.STATUS,
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
		update() {
			this.loading = true;
			this.axios
				.post(ENDPOINTS.RESERVATION.SETTINGS.WEBINTERFACE.OFFLINEMESSAGE.UPDATE, {
					offlineTitle: this.offlineTitle,
					offlineBody: this.offlineBody,
				})
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_dataUpdated"),
							color: "success",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: res.data.msg || res.data.STATUS,
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>

<style scoped>
#editor,
#editor2 {
	height: 180px;
	width: 100%;
}
</style>
