<template>
  <v-container class="pa-0" fluid>

    <v-row class="pa-0 ma-0">
      <create-email-template-component @closeCreateDialog="closeCreateDialog" :showCreateDialog="showCreateDialog" :variations="variations"/>

      <v-dialog scrollable v-model="dialog" fullscreen>
        <v-card>
          <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header pt-1">
            <span>{{ editedItem.name }} </span>
            <v-spacer/>
            <v-btn @click="close" class="ma-0" text color="error">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="px-1 py-10">
            <v-form lazy-validation ref="form">
              <v-row class="mx-0">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="editedItem.tmplName"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :rules="[rules.required]"
                    :label="$t('erp.lang_TemplateName')"
                    outlined
                    required
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-expansion-panels focusable>
                    <v-expansion-panel
                        class="pa-0 ma-0"
                    >
                      <v-expansion-panel-header class="px-3 py-2">
                        <span>{{ $t('delivery.lang_individual_invoice_variables') }}</span>

                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-0 pt-2">
                        <v-row>
                          <v-col cols="12">
                            <b-form-input :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                                          :placeholder="$t('datatables.lang_search')" v-model="search"/>
                          </v-col>
                          <v-col cols="12">
                            <v-row class="pa-0 ma-0">
                              <v-col
                                  v-show="((editedItem.id > 2  && !elt.includes('password')) || (editedItem.id > 0))"
                                  :key="i" class="pt-0 pb-0"
                                  cols="12" md="6" sm="12"
                                  v-for="(elt , i) in this.variations.filter((v) => v.toLocaleLowerCase().includes(search.toLocaleLowerCase())).filter(val => {
                                    if(parseInt(editedItem.id) > 2 && !val.includes('password')) return val;
                                    else if(parseInt(editedItem.id) <= 2 ) return val;
                                  })">
                                <v-text-field :id="'input_' + i" :value="elt"
                                              :background-color="$vuetify.theme.dark? 'dark-bg' : 'grey lighten-3'" dense outlined
                                              readonly>
                                  <template v-slot:append>
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn @click="copyText('input_' + i , elt)" @mouseout="out" icon v-bind="attrs"
                                              v-on="on">
                                          <v-icon>content_copy</v-icon>
                                        </v-btn>
                                      </template>
                                      {{ toolpitMsg }}
                                    </v-tooltip>
                                  </template>
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>

                <v-col class="py-0" cols="12">
                  <quill-editor id="editor" :data-layout="KEYBOARD.KEYSETS.NORMAL" :options="quillOptions"
                                      :rules="[v => !!v]"
                                      @focus="showTouchKeyboard"
                                      output="html" v-model="editedItem.text"></quill-editor>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right pt-1 pb-1">
            <v-spacer/>
            <v-btn @click="close" class="bg-danger text-light mx-0">
              {{ this.$t('generic.lang_cancel') }}
            </v-btn>
            <v-btn :disabled="this.loading" :loading="this.loading" @click="saveTamplate"
                   class="bg-primary text-light mx-1">
              {{ this.$t('generic.lang_save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-col class="mt-0 pt-0" cols="12">
        <v-row>
          <v-col class="pa-0 ma-0" cols="12">
            <v-data-table
                :items="templates"
                :headers="headers"
                :loading="loading"
            >
              <template v-slot:header.crud="{ header }">
                <v-btn @click="create" class="pa-0 ma-0" color="primary" icon>
                  <v-icon>add</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.crud="{item}">
                <v-row align="center" justify="center">
                  <v-btn
                    class="mx-auto"
                    color="primary"
                    icon
                    text
                    @click="editEntry(item)"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>

                  <v-btn
                    class="mx-auto"
                    color="error"
                    icon
                    text
                    @click="deleteEntry(item)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';

import mixin from "@/mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";

import CreateEmailTemplateComponent from "@/components/reservation/settings/CreateEmailTemplateComponent";

export default {
  name: "EmailTemplatesComponent",
  components: {CreateEmailTemplateComponent, quillEditor},
  mixins: [mixin],
  data() {
    return {
      showCreateDialog: false,
      editedItem: {
        name: "",
        text: "",
      },
      default: {
        name: "",
        text: "",
      },
      templates: [
        {
          id: '1',
          tmplName: this.$t('generic.lang_resetPassword'),
          text: '',
        },
        {
          id: '2',
          tmplName: this.$t('generic.lang_neues_passwort'),
          text: '',
        },
        {
          id: '3',
          tmplName: this.$t('generic.lang_welcome'),
          text: '',
        },
        {
          id: '4',
          tmplName: this.$t('generic.lang_newOrder'),
          text: '',
        },
        {
          id: '5',
          tmplName: this.$t('emailTmp.lang_GuthabenAufladen'),
          text: '',
        },
        {
          id: '6',
          tmplName: this.$t('generic.lang_newMenu'),
          text: '',
        },
        {
          id: '7',
          tmplName: this.$t('generic.lang_yourFavoriteDish'),
          text: '',
        },
        {
          id: '8',
          tmplName: this.$t('emailTmp.lang_infoSheet'),
          text: '',
        },
        {
          id: '9',
          tmplName: this.$t('emailTmp.lang_allergenProfil'),
          text: '',
        },
      ],
      quillOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}],
          ],
        },
      },
      edit: false,
      loading: false,
      ENDPOINTS,
      search: "",
      dialog: false,
      toolpitMsg: this.$t('generic.lang_CopyToClipboard'),
      rules: {
        required: (val) => {
          return !!val || this.$t('generic.lang_requiredField')
        },
      }
    }
  },

  methods: {
    saveTamplate() {
      if (!this.$refs.form.validate()) return;
      if (this.editedItem.text === "") {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_pleaseSetYourTemplateText'),
          color: "error"
        });
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.EMAILTEMPLATES.UPDATE, {
        id: this.editedItem.id,
        name: this.editedItem.tmplName,
        text: this.editedItem.text,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          // update dataTable
          this.getAllTemplates();
          this.close();
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    getAllTemplates() {
      this.loading = true;
      this.axios.post(ENDPOINTS.RESERVATION.SETTINGS.EMAILTEMPLATES.GETALL)
          .then((res) => {
            if(res.data && res.data.length>0){
              this.templates = res.data
            }
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    out() {
      this.toolpitMsg = this.$t('generic.lang_CopyToClipboard')
    },
    copyText(id, elt) {
      elt = elt.substring(elt.search('{{'), elt.search('}}') + 2);
      //console.log(id)
      var copyText = document.getElementById(id);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.toolpitMsg = this.$t('generic.lang_copied') + ": " + elt;
      this.editedItem.text += " " + elt;
    },
    closeCreateDialog(){
      this.getAllTemplates();
      this.showCreateDialog = false;
    },
    create() {
      this.showCreateDialog = true;
    },
    editEntry(entry) {
      this.editedItem = Object.assign({}, entry);
      this.edit = true;
      this.dialog = true;
    },
    deleteEntry(entry) {
      this.loading = true;
      this.$swal({
        title: this.$t("settings.lang_deleteTemplate"),
        text: this.$t("erp.lang_deletePriceTmplText"),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios
            .post(ENDPOINTS.RESERVATION.SETTINGS.EMAILTEMPLATES.DELETE, {
              id: entry.id,
            })
            .then((res) => {
              if (res.data.status) {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_ActionSuccessful"),
                  color: "success",
                });

                this.getAllTemplates();
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_errorOccurred"),
                  color: "error",
                });
              }
              this.loading = false;
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.default);
      this.$refs.form.reset();
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('generic.lang_template'),
          value: 'tmplName',
        },
        {
          text: '',
          value: 'crud',
          width: 100,
          align: 'center',
          sortable: false,
        },
      ]
    },
    variations() {
      return [
        this.$t('customers.lang_cust_prename') + "  - {{Prename}}",
        this.$t('customers.lang_cust_name') + "  - {{Lastname}}",
        this.$t('settings.lang_companyName') + "  - {{Company}}",
        this.$t('generic.lang_address') + "  - {{Adress}}",
        this.$t('customers.lang_cust_zip') + "  - {{Zip}}",
        this.$t('settings.lang_city') + "  - {{City}}",
        this.$t('generic.lang_country') + "  - {{Country}}",
        this.$t('customers.lang_cust_anrede') + "  - {{Salutation}}",
      ]
    },
  },
  mounted() {
    this.getAllTemplates()
  }
}
</script>

<style scoped>
v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none !important;
  border: none !important;
}

#editor {
  height: 180px;
  width: 100%;
}
</style>
