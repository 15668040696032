<template>
	<v-card elevation="0">
		<v-card-text class="pa-0">
			<v-col class="pa-0 ma-0" cols="12">
				<v-form lazy-validation ref="form">
					<v-row class="pa-0 ma-0">
						<v-col class="" cols="9">
							<v-subheader
								><strong>{{ $t("generic.lang_welcomeTitel") }}</strong></v-subheader
							>
							<quill-editor
								id="editor1"
								:data-layout="KEYBOARD.KEYSETS.NORMAL"
								:options="quillOptions"
								@focus="showTouchKeyboard"
								output="html"
								class="mb-4"
								v-model="welcomeTitle"
							>
							</quill-editor>
						</v-col>
						<v-col class="" cols="3">
							<v-switch v-model="welcomeTitleStatus" :label="$t('generic.lang_enable')" hide-details inset>
							</v-switch>
						</v-col>
						<v-col class="" cols="9">
							<v-subheader
								><strong>{{ $t("generic.lang_welcomeBody") }}</strong></v-subheader
							>
							<quill-editor
								id="editor2"
								:data-layout="KEYBOARD.KEYSETS.NORMAL"
								:options="quillOptions"
								@focus="showTouchKeyboard"
								output="html"
								class="mb-8"
								v-model="welcomeBody"
							>
							</quill-editor>
						</v-col>
						<v-col class="" cols="3">
							<v-switch v-model="welcomeBodyStatus" :label="$t('generic.lang_enable')" hide-details inset>
							</v-switch>
						</v-col>

						<v-col class="" cols="9">
							<v-subheader
								><strong>{{ $t("generic.lang_constReservationType") }}</strong></v-subheader
							>
							<quill-editor
								id="editor2"
								:options="quillOptions"
								output="html"
								class="mb-8"
								v-model="reservationTypeBody"
							>
							</quill-editor>
						</v-col>
						<v-col class="" cols="3">
							<v-switch
								v-model="reservationTypeBodyStatus"
								:label="$t('generic.lang_enable')"
								hide-details
								inset
							>
							</v-switch>
						</v-col>

						<v-col class="" cols="9">
							<v-textarea
								:data-layout="KEYBOARD.KEYSETS.NORMAL"
								:label="$t('generic.lang_alertBody')"
								hide-details
								outlined
								@focus="showTouchKeyboard"
								rows="3"
								v-model="welcomeAlert"
							>
							</v-textarea>
						</v-col>
						<v-col class="" cols="3">
							<v-switch v-model="welcomeAlertStatus" :label="$t('generic.lang_enable')" hide-details inset>
							</v-switch>
						</v-col>
						<v-col class="" cols="12">
							<v-select
								outlined
								:label="$t('generic.lang_alertColor')"
								item-value="name"
								item-text="name"
								:items="colors"
								chips
								clearable
								v-model="welcomeAlertColor"
							>
								<template v-slot:selection="{ item }">
									<v-chip dark :color="item.name"> </v-chip>
									{{ item.name }}
								</template>
								<template v-slot:item="{ item, attrs, on }">
									<v-list-item v-on="on" v-bind="attrs">
										<v-list-item-content>
											<v-list-item-title>
												<v-row no-gutters align="center">
													<v-chip :color="item.name" small></v-chip>
													<span class="ml-2">{{ item.name }}</span>
												</v-row>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</template>
							</v-select>
						</v-col>
					</v-row>
				</v-form>
				<v-card-actions class="card-footer text-right pt-1 pb-1">
					<v-spacer />
					<v-btn :disabled="loading" :loading="loading" @click="update" class="bg-success text-light mx-1">
						{{ $t("generic.lang_apply") }}
					</v-btn>
				</v-card-actions>
			</v-col>
			<div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
				<vue-touch-keyboard
					v-if="touchKeyboard.visible"
					id="onScreenKeyboard"
					:accept="hideTouchKeyboard"
					:cancel="hideTouchKeyboard"
					:defaultKeySet="touchKeyboard.keySet"
					:input="touchKeyboard.input"
					:layout="touchKeyboard.layout"
					:options="touchKeyboard.options"
					class="internalWidth"
				/>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { Swatches } from "vue-swatches";
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import Quill from "quill";
import QuillResize from "quill-resize-module";

Quill.register("modules/resize", QuillResize);

const FontAttributor = Quill.import("attributors/class/font");
let SizeStyle = Quill.import("attributors/style/size");
FontAttributor.whitelist = ["Poppins", "Roboto", "Nunito", "Dancingscript"];
SizeStyle.whitelist = [
	"8px",
	"10px",
	"12px",
	"14px",
	"16px",
	"18px",
	"20px",
	"24px",
	"28px",
	"32px",
	"36px",
	"40px",
	"48px",
	"56px",
	"64px",
	"72px",
	"80px",
	"96px",
	"128px",
];
Quill.register(FontAttributor, true);
Quill.register(SizeStyle, true);

// Fix : Dynamicaly changing font style for each font item
FontAttributor.whitelist.forEach((font) => {
	const style = document.createElement("style");
	style.innerHTML = `.ql-font-${font} { font-family: ${font}; }`;
	document.head.appendChild(style);
});

export default {
	name: "WelcomeConfigComponent",
	components: { quillEditor, Swatches },
	mixins: [mixin],
	data() {
		return {
			loading: false,
			quillOptions: {
				modules: {
					toolbar: [
						[{ color: [] }],
						["image", "video", "link", "clean"],
						["bold", "italic", "underline", "strike"],
						[{ size: SizeStyle.whitelist }],
						[{ header: [1, 2, 3, 4, 5, 6, false] }],
						[{ align: [] }],
						[{ font: FontAttributor.whitelist }],
						[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
					],
				},
			},
			welcomeTitle: null,
			welcomeBody: null,
			welcomeAlert: null,
			reservationTypeBody: null,
			welcomeAlertColor: "success",
			welcomeTitleStatus: false,
			welcomeBodyStatus: false,
			welcomeAlertStatus: false,
			reservationTypeBodyStatus: false,
			colors: [
				{ name: "primary" },
				{ name: "purple" },
				{ name: "success" },
				{ name: "error" },
				{ name: "warning" },
			],
		};
	},
	methods: {
		getData() {
			this.loading = true;
			this.axios
				.post(ENDPOINTS.RESERVATION.SETTINGS.WEBINTERFACE.WELCOMECONFIG.GET)
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						this.welcomeTitle = res.data.data.welcomeTitle;
						this.welcomeBody = res.data.data.welcomeBody;
						this.welcomeAlert = res.data.data.welcomeAlert;
						this.reservationTypeBody = res.data.data.reservationTypeBody;
						this.welcomeAlertColor = res.data.data.welcomeAlertColor;
						this.welcomeTitleStatus = res.data.data.welcomeTitleStatus === "1";
						this.welcomeBodyStatus = res.data.data.welcomeBodyStatus === "1";
						this.welcomeAlertStatus = res.data.data.welcomeAlertStatus === "1";
						this.reservationTypeBodyStatus = res.data.data.reservationTypeBodyStatus;
					} else {
						Events.$emit("showSnackbar", {
							message: res.data.msg || res.data.STATUS,
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
		update() {
			this.loading = true;
			this.axios
				.post(ENDPOINTS.RESERVATION.SETTINGS.WEBINTERFACE.WELCOMECONFIG.UPDATE, {
					welcomeTitle: this.welcomeTitle,
					welcomeBody: this.welcomeBody,
					welcomeAlert: this.welcomeAlert,
					reservationTypeBody: this.reservationTypeBody,
					welcomeAlertColor: this.welcomeAlertColor,
					welcomeTitleStatus: this.welcomeTitleStatus,
					welcomeBodyStatus: this.welcomeBodyStatus,
					welcomeAlertStatus: this.welcomeAlertStatus,
					reservationTypeBodyStatus: this.reservationTypeBodyStatus,
				})
				.then((res) => {
					if (res.data.STATUS === "SUCCESS") {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_dataUpdated"),
							color: "success",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: res.data.msg || res.data.STATUS,
							color: "error",
						});
					}
				})
				.catch((err) => {
					Events.$emit("showSnackbar", {
						message: this.$t("generic.lang_errorOccurred") + " " + err.message,
						color: "error",
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	mounted() {
		this.getData();
	},
};
</script>

<style scoped>
#editor1,
#editor2 {
	height: 180px;
	width: 100%;
}
</style>

<style>
/* Show fonts name / sizes in the dropdown menu */
.ql-snow .ql-picker-options .ql-picker-item {
	font-family: inherit;
}
.ql-snow .ql-picker-options {
	height: 200px;
	overflow-y: auto;
}
.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
	content: attr(data-value) !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
	content: attr(data-value) !important;
}

.theme--light.v-card > .v-card__text {
	color: inherit;
}
</style>
